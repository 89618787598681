var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tabs"
  }, [_c('b-nav', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-nav-item', {
    attrs: {
      "to": "#",
      "active": _vm.$route.hash === '#' || _vm.$route.hash === ''
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('general')))])], 1)]), _c('b-nav-item', {
    attrs: {
      "to": "#change-password",
      "active": _vm.$route.hash === '#change-password'
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LockIcon",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('changePassword')))])], 1)]), _c('b-nav-item', {
    attrs: {
      "to": "#change-payment-password",
      "active": _vm.$route.hash === '#change-payment-password'
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "keyOutline",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('myAccount.paymentPassword.title')))])], 1)]), _c('b-nav-item', {
    attrs: {
      "to": "#mfa",
      "active": _vm.$route.hash === '#mfa'
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "KeyIcon",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('myAccount.mfa.title')))])], 1)])], 1), _c('div', {
    staticClass: "tab-content"
  }, [_c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#' || _vm.$route.hash === ''
    }]
  }, [_vm.userData ? _c('AccountSettingGeneral', {
    attrs: {
      "employee-data": _vm.employeeData,
      "user-data": _vm.userData
    }
  }) : _vm._e()], 1), _vm.$route.hash === '#change-password' ? _c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#change-password'
    }]
  }, [_c('AccountSettingPassword')], 1) : _vm._e(), _vm.$route.hash === '#change-payment-password' ? _c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#change-payment-password'
    }]
  }, [_c('AccountSettingPaymentPassword')], 1) : _vm._e(), _vm.$route.hash === '#mfa' ? _c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#mfa'
    }]
  }, [_c('AccountSettingMFA', {
    attrs: {
      "mfaListProp": _vm.mfaList,
      "isEnableMfaLoginProp": _vm.isEnableMfaLogin
    },
    on: {
      "update:mfaListProp": function updateMfaListProp($event) {
        _vm.mfaList = $event;
      },
      "update:mfa-list-prop": function updateMfaListProp($event) {
        _vm.mfaList = $event;
      },
      "update:isEnableMfaLoginProp": function updateIsEnableMfaLoginProp($event) {
        _vm.isEnableMfaLogin = $event;
      },
      "update:is-enable-mfa-login-prop": function updateIsEnableMfaLoginProp($event) {
        _vm.isEnableMfaLogin = $event;
      }
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }