<template>
  <div class="tabs">
    <b-nav pills>
      <b-nav-item
        to="#"
        :active="$route.hash === '#' || $route.hash === ''"
      >
        <div class="d-flex-center gap-1">
          <feather-icon
            icon="UserIcon"
            size="18"
          />
          <span class="font-weight-bold">{{ $t('general') }}</span>
        </div>
      </b-nav-item>
      <b-nav-item
        to="#change-password"
        :active="$route.hash === '#change-password'"
      >
        <div class="d-flex-center gap-1">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="font-weight-bold">{{ $t('changePassword') }}</span>
        </div>
      </b-nav-item>
      <b-nav-item
        to="#change-payment-password"
        :active="$route.hash === '#change-payment-password'"
      >
        <div class="d-flex-center gap-1">
          <IAmIcon
            icon="keyOutline"
            size="18"
          />
          <span class="font-weight-bold">{{ $t('myAccount.paymentPassword.title') }}</span>
        </div>
      </b-nav-item>
      <b-nav-item
        to="#mfa"
        :active="$route.hash === '#mfa'"
      >
        <div class="d-flex-center gap-1">
          <feather-icon
            icon="KeyIcon"
            size="18"
          />
          <span class="font-weight-bold">{{ $t('myAccount.mfa.title') }}</span>
        </div>
      </b-nav-item>
    </b-nav>
    <div class="tab-content">
      <div
        :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]"
        class="p-0"
      >
        <AccountSettingGeneral
          v-if="userData"
          :employee-data="employeeData"
          :user-data="userData"
        />
      </div>
      <div
        v-if="$route.hash === '#change-password'"
        :class="['tab-pane', { 'active': $route.hash === '#change-password' }]"
        class="p-0"
      >
        <AccountSettingPassword />
      </div>
      <div
        v-if="$route.hash === '#change-payment-password'"
        :class="['tab-pane', { 'active': $route.hash === '#change-payment-password' }]"
        class="p-0"
      >
        <AccountSettingPaymentPassword />
      </div>
      <div
        v-if="$route.hash === '#mfa'"
        :class="['tab-pane', { 'active': $route.hash === '#mfa' }]"
        class="p-0"
      >
        <AccountSettingMFA
          :mfaListProp.sync="mfaList"
          :isEnableMfaLoginProp.sync="isEnableMfaLogin"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BNav, BNavItem } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { getUserData } from '@/api/auth/utils'

export default {
  components: {
    BNav,
    BNavItem,
    AccountSettingGeneral: () => import('./AccountSettingGeneral.vue'),
    AccountSettingPassword: () => import('./AccountSettingPassword.vue'),
    AccountSettingPaymentPassword: () => import('./AccountSettingPaymentPassword.vue'),
    AccountSettingMFA: () => import('./AccountSettingMFA.vue'),
  },
  setup() {
    const { userData, employeeData } = getUserData()
    // cache de lazy b-nav-item
    const mfaList = ref(null)
    const isEnableMfaLogin = ref(null)
    return {
      userData, employeeData, mfaList, isEnableMfaLogin,
    }
  },
}
</script>
